html {
	font-size: 10px;
}

html,
body {
	padding: 0;
	margin: 0;
}

html,
body,
#root,
.App,
.fullHeight {
	height: 91%;
	padding-bottom: 38px;
}

*,
::before,
::after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 1;
}

h1 {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0 auto 2rem;
	font-size: 3rem;
	line-height: 3.2rem;
	color: #000000;
	text-align: left;
	font-weight: 400;
	text-transform: uppercase;
	border-bottom: #d3d3d3;
}

h2 {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0 auto 2rem;
	font-size: 2.5rem;
	line-height: 2.7rem;
	color: #000000;
	text-align: left;
	font-weight: 400;
	text-transform: uppercase;
	border-bottom: #d3d3d3;
}

a {
	text-decoration: none;
	cursor: pointer;
}

a,
a:hover {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.main {
	width: 100%;
	height: 100%;
	max-width: 1450px;
	padding-bottom: 228px !important;
	padding: 0 10px;
	margin: 0 auto;
}

.controlPanel {
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 100%;
	padding: 3rem 0 6rem 25rem;
	border-bottom: 1px solid #d3d3d3;

	.sidebar {
		position: absolute;
		top: 0;
		left: 0;
		width: 25rem;
		height: 100%;
		z-index: 1;
		border-right: 1px solid #d3d3d3;
	}

	.content {
		padding: 0 10px;
		padding-bottom: 153px;
	}
}

.adminLayout {
	height: calc(100% - 6.5rem - 5.8rem - 3.6rem);
}

.dashboardLayout {
	height: calc(100% - 6.5rem - 5.8rem);
}

@media only screen and (max-width: 980px) {
	html {
		font-size: 9px;
	}

	.controlPanel {
		padding: 0 10px;

		.sidebar {
			position: relative;
			width: 100%;
			border: none;
			margin-bottom: 2.5rem;
		}
	}

	html,
	body,
	#root,
	.App,
	.fullHeight {
		height: 91%;
		padding-bottom: 4px;
	}
}
